<template>
  <v-spacer></v-spacer>
</template>

<script>
export default {
  name: 'AppSpacer'
}
</script>

<style></style>
