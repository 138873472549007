<template>
  <v-app-bar-nav-icon v-bind="$attrs"></v-app-bar-nav-icon>
</template>

<script>
export default {
  name: 'AppBarNavIcon'
}
</script>

<style></style>
